// import React, { useEffect } from "react";
// import { FaUsers } from "react-icons/fa";
// import { GiLoveSong, GiMusicalNotes } from "react-icons/gi";
// import { RiUserStarFill } from "react-icons/ri";
// import { getAllAuthors, getAllAudiobooks, getAllUsers, getAllSeries } from "../api";
// import { actionType } from "../Context/reducer";
// import { useStateValue } from "../Context/StateProvider";
// import { bgColors } from "../utils/styles";

// export const DashboardCard = ({ icon, name, count }) => {
//   const bg_color = bgColors[parseInt(Math.random() * bgColors.length)];

//   return (
//     <div
//       style={{ background: `${bg_color}` }}
//       className={`p-4 w-40 gap-3 h-auto rounded-lg shadow-md flex flex-col items-center justify-center`}
//     >
//       {icon}
//       <p className="text-xl text-textColor font-semibold">{name}</p>
//       <p className="text-sm text-textColor">{count}</p>
//     </div>
//   );
// };

// const DashboardHome = () => {
//   const [{ allUsers, allAudiobooks, allAuthors, allSeries }, dispatch] =
//     useStateValue();
//   useEffect(() => {
//     if (!allUsers) {
//       getAllUsers().then((data) => {
//         dispatch({
//           type: actionType.SET_ALL_USERS,
//           allUsers: data.data,
//         });
//       });
//     }

//     if (!allAudiobooks) {
//       getAllAudiobooks().then((data) => {
//         dispatch({
//           type: actionType.SET_ALL_AUDIOBOOKS,
//           allAudiobooks: data.data,
//         });
//       });
//     }

//     if (!allAuthors) {
//       getAllAuthors().then((data) => {
//         dispatch({
//           type: actionType.SET_ALL_AUTHORS,
//           allAuthors: data.data
//         });
//       });
//     }

//     if (!allSeries) {
//       getAllSeries().then((data) => {
//         dispatch({
//           type: actionType.SET_ALL_SERIES,
//           allSeries: data.data
//         });
//       });
//     }
//   }, []);
//   return (
//     <div className="w-full p-6 flex items-center justify-evenly flex-wrap">
//       {/* prettier-ignore */}
//       <DashboardCard icon={<FaUsers className="text-3xl text-textColor" />} name={"Users"} count={allUsers?.length > 0 ? allUsers?.length : 0} />

//       {/* prettier-ignore */}
//       <DashboardCard icon={<GiLoveSong className="text-3xl text-textColor" />} name={"Audiobooks"} count={allAudiobooks?.length > 0 ? allAudiobooks?.length : 0} />

//       {/* prettier-ignore */}
//       <DashboardCard icon={<RiUserStarFill className="text-3xl text-textColor" />} name={"Authors"} count={allAuthors?.length > 0 ? allAuthors?.length : 0} />

//       {/* prettier-ignore */}
//       <DashboardCard icon={<GiMusicalNotes className="text-3xl text-textColor" />} name={"Series"} count={allSeries?.length > 0 ? allSeries?.length : 0} />
//     </div>
//   );
// };

// export default DashboardHome;



import React, { useEffect } from "react";
import { FaUsers } from "react-icons/fa";
import { GiLoveSong, GiMusicalNotes } from "react-icons/gi";
import { RiUserStarFill } from "react-icons/ri";
import { getAllAuthors, getAllAudiobooks, getAllUsers, getAllSeries } from "../api";
import { actionType } from "../Context/reducer";
import { useStateValue } from "../Context/StateProvider";
import { bgColors } from "../utils/styles";

export const DashboardCard = ({ icon, name, count }) => {
  const bg_color = bgColors[parseInt(Math.random() * bgColors.length)];

  return (
    <div
      style={{ background: `${bg_color}` }}
      className="p-4 sm:w-40 h-auto rounded-lg shadow-md flex flex-col items-center justify-center"
    >
      {icon}
      <p className="text-lg sm:text-xl  text-textColor font-semibold">{name}</p>
      <p className="text-sm text-textColor">{count}</p>
    </div>
  );
};

const DashboardHome = () => {
  const [{ allUsers, allAudiobooks, allAuthors, allSeries }, dispatch] =
    useStateValue();

  useEffect(() => {
    if (!allUsers) {
      getAllUsers().then((data) => {
        dispatch({
          type: actionType.SET_ALL_USERS,
          allUsers: data.data,
        });
      });
    }

    if (!allAudiobooks) {
      getAllAudiobooks().then((data) => {
        dispatch({
          type: actionType.SET_ALL_AUDIOBOOKS,
          allAudiobooks: data.data,
        });
      });
    }

    if (!allAuthors) {
      getAllAuthors().then((data) => {
        dispatch({
          type: actionType.SET_ALL_AUTHORS,
          allAuthors: data.data,
        });
      });
    }

    if (!allSeries) {
      getAllSeries().then((data) => {
        dispatch({
          type: actionType.SET_ALL_SERIES,
          allSeries: data.data,
        });
      });
    }
  }, []);

  return (
    <div className="w-full p-6 flex items-center justify-center flex-wrap gap-4">
      {/* prettier-ignore */}
      <DashboardCard icon={<FaUsers className="text-3xl sm:text-3xl text-textColor" />} name={"Users"} count={allUsers?.length > 0 ? allUsers?.length : 0} />

      {/* prettier-ignore */}
      <DashboardCard icon={<GiLoveSong className="text-3xl sm:text-3xl text-textColor" />} name={"Audiobooks"} count={allAudiobooks?.length > 0 ? allAudiobooks?.length : 0} />

      {/* prettier-ignore */}
      <DashboardCard icon={<RiUserStarFill className="text-3xl sm:text-3xl text-textColor" />} name={"Authors"} count={allAuthors?.length > 0 ? allAuthors?.length : 0} />

      {/* prettier-ignore */}
      <DashboardCard icon={<GiMusicalNotes className="text-3xl sm:text-3xl text-textColor" />} name={"Series"} count={allSeries?.length > 0 ? allSeries?.length : 0} />
    </div>
  );
};

export default DashboardHome;
